










































import { Component, PropSync, Vue } from 'vue-property-decorator';
import UserProfileDeleteAccountDialogViewModel from '@/vue-app/view-models/components/user-profile/user-profile-tabs/user-profile-delete-account-dialog-view-model';

@Component({ name: 'UserProfileDeleteAccountDialog' })
export default class UserProfileDeleteAccountDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  user_profile_delete_account_dialog_view_model = Vue.observable(
    new UserProfileDeleteAccountDialogViewModel(),
  );

  closeModal() {
    this.user_profile_delete_account_dialog_view_model.logout();
    this.synced_is_open = false;
  }
}
